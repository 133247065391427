<template>
  <div id="overlay" v-if="overlay"></div>
  <!--        <pre>{{ vv._vuelidate_11.content.$model }}</pre>-->
  <form @submit.prevent="handleSubmit" class="p-fluid" autocomplete="off">

    <div class="grid">
      <div class="col-12 lg:col-4 xl:col-4">
        <!--        tcomercializador-->
        <EditLabel ref="tcomercializador" :input="block.tcomercializador" title="Nombre" :required="true"/>
        <!--        trif_comercializador-->
        <EditLabel ref="trif_comercializador" :input="block.trif_comercializador" title="RIF" :required="true"/>
        <!--        id_cod_reg_cona-->
<!--        <EditLabel ref="nporcentaje_comision" :input="block.nporcentaje_comision" title="Porcentaje de Comision" :required="true"/>-->
      </div>
      <div class="col-12 lg:col-4 xl:col-4">
        <EditLabel ref="ncant_prestamos" :input="block.ncant_prestamos" title="Préstamos" />
      </div>
      <div class="col-12 lg:col-4 xl:col-4">
        <!--        cstatus_comercializador-->
        <EditDropdown
            ref="cstatus_comercializador"
            :options="statusList"
            optionLabel="label"
            optionValue="code"
            :selectedInput="block.cstatus_comercializador"
            @onChange="onChangeStatus"
            title="Estatus"
        />
        <EditLabel ref="dcreacion" :input="formatDate(block.dcreacion)" title="Fecha de creaciôn" disabled/>

      </div>
    </div>

    <!--  <Button type="button" @click="vv.$touch()">$touch</button>-->
    <div class="flex justify-content-end">
      <Button
          class="p-button-raised" icon="pi pi-save"
          type="submit"
          :disabled="vv.$errors[0] || !vv.$anyDirty"
      />
    </div>
  </form>

</template>

<script>

import useVuelidate from '@vuelidate/core'
import EditLabel from "@/components/misc/Labels/EditLabel";

import MainService from "@/service/backend/MainService";
import BlockService from "@/service/backend/BlockService";
import generalMixin from "@/mixins/generalMixin";
import EditDropdown from "@/components/misc/Labels/EditDropdown";

export default {
  name: "Tab1",
  components: {EditDropdown, EditLabel},
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService;
    this.blockService = new BlockService;
  },
  setup() {
    let vv = useVuelidate()
    return {vv};
  },
  props: {
    block: Object,
  },
  data() {
    return {
      overlay: false,
      statusList: null,
    }
  },
  async mounted() {
    this.statusList = this.getEntityStatus();
    
  },
  methods: {
    onChangeStatus(cstatus_comercializador) {
      this.selectedStatus = cstatus_comercializador.value;
    },
    handleSubmit() {
      this.submitted = true;
      const block = {
        id_comercializador: this.block.id,
        tcomercializador: this.$refs.tcomercializador.content,
        cstatus_comercializador: this.selectedStatus,//this.$refs.cstatus_comercializador.content,
      };
      this.saveBlock(block);
    },
    async saveBlock(block) {
      try {
        this.overlay = block;
        const response = await this.blockService.updateBlockDetails(block);
        if (response.error) {
          this.$toast.add({
            severity: 'error',
            summary: '',
            detail: 'No se pudo editar el comercializador | ' + response.error,
            life: 10000
          });
          this.overlay = false;
        } else {
          this.$toast.add({severity: 'success', summary: '', detail: `Comercializador ${block.tcomercializador} editado`, life: 3000});
          this.$emit('close', close);
        }

      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'No se pudo editar el comercializador | ' + e.message,
          life: 10000
        });
      }
      this.overlay = false;
    }
  }

}
</script>
<style>
.municipio-error {
  margin-top: -10px;
  margin-left: 10px;
}
</style>