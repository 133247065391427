<template>
  <div id="overlay" v-if="overlay"></div>
  <div>
    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="block_container">
            <div class="bloc1"><h3>Bloques {{ totalRecords && '(' + totalRecords + ')' }}</h3></div>
          </div>
        </div>
      </div>
      <div :class="`col-12 lg:col-${getTableLayoutClass()}`">
        <div class="card p-fluid" id="number-container">
          <!--     Filter goes here     -->
          <Filter
              @get-data="getData"
              page="blocks"
          />
        </div>
      </div>
      <div :class="`col-12 lg:col-${12-getTableLayoutClass()}`">
        <div class="card">
          <Toolbar class="mb-4">
            <template #start>
              <Button label="" icon="pi pi-plus" class="p-button-success mr-2" @click="createBlock"/>
            </template>
            <template #end>

              <Button label="" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                      :disabled="!selectedBlocks || !selectedBlocks.length"/>

            </template>
          </Toolbar>
          <DataTable :value="blocks" :lazy="true" :paginator="true" :rows="10"
                     v-model:filters="filters" ref="dt"
                     dataKey="id"
                     :totalRecords="totalRecords"
                     :loading="loadingTable" @page="onPage($event)"
                     @sort="onSort($event)"
                     @filter="onFilter($event)"
                     v-model:selection="selectedBlocks"
                     :selectAll="selectAll"
                     responsiveLayout="scroll"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table"
                     showGridlines
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     :rowsPerPageOptions="[5,10,25,50]"
                     currentPageReportTemplate="{first} al {last} de {totalRecords}"
                     @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect">

            <template #header>
              <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                <h5 class="mb-2 md:m-0 p-as-md-center">Bloques</h5>
                <span class="p-input-icon-left">
                                      <i class="pi pi-search"/>
                                      <InputText v-model="searchFilters['global'].value" placeholder="Buscar..."
                                                 v-on:keyup.enter="onFilter"/>
                                  </span>
              </div>
            </template>
            <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

            <Column field="cstatus_comercializador" header="Estatus" :sortable="true"
                    bodyStyle="text-align: center; white-space: nowrap !important;">
              <template #body="{data}">
                <span :class="'status-badge ' + getEntityStatusClass(data.cstatus_comercializador)">
                  {{ getEntityStatus(data.cstatus_comercializador).toUpperCase() }}
                </span>
              </template>
            </Column>


            <Column field="tcomercializador" header="Nombre" :sortable="true" filterMatchMode="startsWith" ref="id_comercializador"/>
            <Column field="dcreacion" header="Fecha de creación" :sortable="true" style="min-width:4rem"
                    bodyStyle="text-align: center;">
              <template #body="{data}">
                {{ formatDate(data.dcreacion) }}
              </template>
            </Column>

            <Column field="num_bancas" header="Número de Bancas" :sortable="true"
            bodyStyle="text-align: center;">
            <template #body="{data}">
              {{ data.num_bancas }}
            </template>
            </Column>

            <Column field="dborrado_virtual" header="Fecha de borrado" :sortable="true" bodyStyle="text-align: center;"
                    v-if=" filters && filters.displayDeleted">
              <template #body="{data}">
                {{ data.dborrado_virtual && formatDate(data.dborrado_virtual) }}
              </template>
            </Column>
            <Column field="cstatus_comercializador" header="Acción" style="width:10px;">
              <template #body="props">
                <div class="flex justify-content-between">
                  <span class="p-row-editor-init-icon pi pi-fw pi-lock edit-icon"
                        v-tooltip.bottom="'Bloquear'"
                        v-if="props.data.cstatus_comercializador!==4"
                        v-bind:class="{
                                    'locked': props.data.cstatus_comercializador === 2,
                                    'unlocked': props.data.cstatus_comercializador === 1,
                                    'fully-locked': props.data.cstatus_comercializador === 3
                                  }"
                        @click="confirmBlockBlock(props.data)"></span>
                  <span class="p-row-editor-init-icon pi pi-fw pi-pencil edit-icon"
                        v-tooltip.bottom="'Editar'"
                        @click="editBlock(props.data)"></span>
                </div>
              </template>
            </Column>
          </DataTable>

        </div>
      </div>
    </div>
  </div>

  <EditBlockDialog
      :block="block"
      :blockDetails="blockDetails"
      :show="displayEditBlock"
      ref="editBlockDialog"
      @new-user="pushNewBlock"
      @close="closeBlockDialog"/>

  <Dialog v-model:visible="blockBlockDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span v-if="block">¿{{
          this.block.cstatus_comercializador === 2 ? 'Desbloquear' : 'Bloquear'
        }} bloque <b>{{ block && block.tcomercializador }}</b>?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="blockBlockDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="blockBlock(block)"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteBlocksDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>¿Borrar las bloques seleccionados?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteBlocksDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelectedBlocks"/>
    </template>
  </Dialog>

  <Toast/>

</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import Filter from "@/components/Filters/Filter";
import BlockService from "@/service/backend/BlockService";
import {FilterMatchMode} from "primevue/api";
import EditBlockDialog from "@/components/Settings/Blocks/Dialog/EditBlockDialog";

export default {
  components: {
    'Filter': Filter,
    'EditBlockDialog': EditBlockDialog,
  },
  mixins: [generalMixin],
  data() {
    return {
      blocks: null,
      totalRecords: null,
      identity: null,
      loadingTable: false,
      searchFilters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id_comercializador': {value: '', matchMode: 'contains'},
        'tcomercializador': {value: '', matchMode: 'contains'},
        'dcreacion': {value: '', matchMode: 'contains'},
      },
      filterData: null,
      displayEditBlock: false,
      block: null,
      blockDetails: null,
      overlay: false,

      deleteBlocksDialog: null,
      selectedBlocks: null,
      selectAll: null,

      blockBlockDialog: false,
      deleteBlockDialog: false,

    }
  },
  userService: null,
  created() {
    this.blockService = new BlockService;
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  async mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      searchFilters: this.searchFilters
    };
    await this.getData();
  },
  methods: {
    async getData(filters) {
      this.selectedBlocks = null;
      if (filters) {
        filters.lista = null;
        this.filterData = filters;
      }
      this.lazyParams.identity = this.identity;
      this.lazyParams.filters = this.filterData;
      let payload = Object.assign(this.lazyParams);
      this.loadingTable = true;
      const data = await this.blockService.getAll(payload);
      this.blocks = data.rows;
      this.totalRecords = Number(data.total);
      this.loadingTable = false;
    },
    onPage(event) {
      this.lazyParams = event;
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onSort(event) {
      this.lazyParams = event;
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onFilter() {
      this.lazyParams.searchFilters = this.searchFilters;
      this.lazyParams.filters = this.filters;
      this.getData();
    },
    onSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.blockService.getAll().then(data => {
          this.selectAll = true;
          this.selectedBlocks = data.blocks;
        });
      } else {
        this.selectAll = false;
        this.selectedBlocks = [];
      }
    },
    onRowSelect() {
      this.selectAll = this.selectedBlocks.length === this.totalRecords;
    },
    onRowUnselect() {
      this.selectedBlocks = false;
    },
    createBlock() {
      this.$store.commit('setBlock', {});
      this.$router.push('/bloques/agregar-1');
    },
    async editBlock(block) {
      this.overlay = true;
      this.block = {...block};
      this.displayEditBlock = true;
    },
    closeBlockDialog() {
      this.getData();
      this.displayEditBlock = false;
      this.overlay = false;
    },
    pushNewBlock(value) {
      this.blocks.push(value);
      this.closeBlockDialog();
    },
    confirmDeleteSelected() {
      this.deleteBlocksDialog = true;
    },
    async deleteSelectedBlocks() {
      // this.loadingTable = true;
      let idsToDelete = [];
      this.selectedBlocks.forEach((item) => {
        idsToDelete.push(item.id)
      });
      this.deleteBlocksDialog = false
      const response = await this.blockService.deleteBlock({idsToDelete: idsToDelete.toString()});
      this.blocks = this.blocks.filter(val => !this.selectedBlocks.includes(val));

      this.selectedBlocks = null
      // this.loadingTable = false;
      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'Bloque(s) no eliminado(s) | ' + response.error,
          life: 10000
        });
      } else {
        this.$toast.add({severity: 'success', summary: '', detail: 'Bloque(s) eliminado(s)', life: 3000});
      }


    },
    confirmBlockBlock(block) {
      this.block = block;
      this.blockBlockDialog = true;
    },
    async blockBlock() {
      this.loadingTable = true;
      this.blockBlockDialog = false;

      const response = await this.blockService.blockBlock({
        id_comercializador: this.block.id_comercializador,
        cstatus_comercializador: this.block.cstatus_comercializador,
      });
      await this.getData();
      this.loadingTable = false;

      const preaction = this.block.cstatus_comercializador === 2 ? 'des' : '';
      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `Bloque no pudo ser ${preaction}bloqueado | ${response.error}`,
          life: 10000
        });
      } else {
        this.$toast.add({severity: 'success', summary: '', detail: `Bloque ${preaction}bloqueado`, life: 3000});
      }

    }

  },

}
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    align-items: start;
  }
}

.user-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .user-image {
  width: 50px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

#role, #status {
  margin-top: 10px;
}

.field {
  padding: 0 20px 0 20px;
}

.fully-locked {
  //background-color: #db3636;
  border-color: #db3636;
  color: #db3636;
}

.locked {
  //background-color: #ffc107;
  border-color: #ffc107;
  color: #ffc107;
}

.unlocked {
  //background-color: var(--surface-400);
  border-color: var(--surface-400);
  //color: var(--green-banklot)
}

.normal {
  background-color: var(--surface-400);
  border-color: var(--surface-400);
  color: var(--green-banklot)
}

.status-badge {
  //width: 86px; //max-content;'
}

.edit-icon {
  margin: 10px;
}

</style>